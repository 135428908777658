/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-latest-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 15px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  width: max-content;
  max-width: 275px;
  visibility: visible;
}
.ant-latest-tooltip-hidden {
  display: none;
}
.ant-latest-tooltip-placement-top,
.ant-latest-tooltip-placement-topLeft,
.ant-latest-tooltip-placement-topRight {
  padding-bottom: 13px;
}
.ant-latest-tooltip-placement-right,
.ant-latest-tooltip-placement-rightTop,
.ant-latest-tooltip-placement-rightBottom {
  padding-left: 13px;
}
.ant-latest-tooltip-placement-bottom,
.ant-latest-tooltip-placement-bottomLeft,
.ant-latest-tooltip-placement-bottomRight {
  padding-top: 13px;
}
.ant-latest-tooltip-placement-left,
.ant-latest-tooltip-placement-leftTop,
.ant-latest-tooltip-placement-leftBottom {
  padding-right: 13px;
}
.ant-latest-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #2D2943;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-latest-tooltip-arrow {
  position: absolute;
  display: block;
  width: 20.14213562px;
  height: 20.14213562px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-latest-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 10px;
  height: 10px;
  margin: auto;
  background-color: #2D2943;
  content: '';
  pointer-events: auto;
}
.ant-latest-tooltip-placement-top .ant-latest-tooltip-arrow,
.ant-latest-tooltip-placement-topLeft .ant-latest-tooltip-arrow,
.ant-latest-tooltip-placement-topRight .ant-latest-tooltip-arrow {
  bottom: -7.14213562px;
}
.ant-latest-tooltip-placement-top .ant-latest-tooltip-arrow-content,
.ant-latest-tooltip-placement-topLeft .ant-latest-tooltip-arrow-content,
.ant-latest-tooltip-placement-topRight .ant-latest-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-10.07106781px) rotate(45deg);
}
.ant-latest-tooltip-placement-top .ant-latest-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-latest-tooltip-placement-topLeft .ant-latest-tooltip-arrow {
  left: 13px;
}
.ant-latest-tooltip-placement-topRight .ant-latest-tooltip-arrow {
  right: 13px;
}
.ant-latest-tooltip-placement-right .ant-latest-tooltip-arrow,
.ant-latest-tooltip-placement-rightTop .ant-latest-tooltip-arrow,
.ant-latest-tooltip-placement-rightBottom .ant-latest-tooltip-arrow {
  left: -7.14213562px;
}
.ant-latest-tooltip-placement-right .ant-latest-tooltip-arrow-content,
.ant-latest-tooltip-placement-rightTop .ant-latest-tooltip-arrow-content,
.ant-latest-tooltip-placement-rightBottom .ant-latest-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(10.07106781px) rotate(45deg);
}
.ant-latest-tooltip-placement-right .ant-latest-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-latest-tooltip-placement-rightTop .ant-latest-tooltip-arrow {
  top: 5px;
}
.ant-latest-tooltip-placement-rightBottom .ant-latest-tooltip-arrow {
  bottom: 5px;
}
.ant-latest-tooltip-placement-left .ant-latest-tooltip-arrow,
.ant-latest-tooltip-placement-leftTop .ant-latest-tooltip-arrow,
.ant-latest-tooltip-placement-leftBottom .ant-latest-tooltip-arrow {
  right: -7.14213562px;
}
.ant-latest-tooltip-placement-left .ant-latest-tooltip-arrow-content,
.ant-latest-tooltip-placement-leftTop .ant-latest-tooltip-arrow-content,
.ant-latest-tooltip-placement-leftBottom .ant-latest-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-10.07106781px) rotate(45deg);
}
.ant-latest-tooltip-placement-left .ant-latest-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-latest-tooltip-placement-leftTop .ant-latest-tooltip-arrow {
  top: 5px;
}
.ant-latest-tooltip-placement-leftBottom .ant-latest-tooltip-arrow {
  bottom: 5px;
}
.ant-latest-tooltip-placement-bottom .ant-latest-tooltip-arrow,
.ant-latest-tooltip-placement-bottomLeft .ant-latest-tooltip-arrow,
.ant-latest-tooltip-placement-bottomRight .ant-latest-tooltip-arrow {
  top: -7.14213562px;
}
.ant-latest-tooltip-placement-bottom .ant-latest-tooltip-arrow-content,
.ant-latest-tooltip-placement-bottomLeft .ant-latest-tooltip-arrow-content,
.ant-latest-tooltip-placement-bottomRight .ant-latest-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(10.07106781px) rotate(45deg);
}
.ant-latest-tooltip-placement-bottom .ant-latest-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-latest-tooltip-placement-bottomLeft .ant-latest-tooltip-arrow {
  left: 13px;
}
.ant-latest-tooltip-placement-bottomRight .ant-latest-tooltip-arrow {
  right: 13px;
}
.ant-latest-tooltip-pink .ant-latest-tooltip-inner {
  background-color: #eb2f96;
}
.ant-latest-tooltip-pink .ant-latest-tooltip-arrow-content {
  background-color: #eb2f96;
}
.ant-latest-tooltip-magenta .ant-latest-tooltip-inner {
  background-color: #eb2f96;
}
.ant-latest-tooltip-magenta .ant-latest-tooltip-arrow-content {
  background-color: #eb2f96;
}
.ant-latest-tooltip-red .ant-latest-tooltip-inner {
  background-color: #f5222d;
}
.ant-latest-tooltip-red .ant-latest-tooltip-arrow-content {
  background-color: #f5222d;
}
.ant-latest-tooltip-volcano .ant-latest-tooltip-inner {
  background-color: #fa541c;
}
.ant-latest-tooltip-volcano .ant-latest-tooltip-arrow-content {
  background-color: #fa541c;
}
.ant-latest-tooltip-orange .ant-latest-tooltip-inner {
  background-color: #fa8c16;
}
.ant-latest-tooltip-orange .ant-latest-tooltip-arrow-content {
  background-color: #fa8c16;
}
.ant-latest-tooltip-yellow .ant-latest-tooltip-inner {
  background-color: #fadb14;
}
.ant-latest-tooltip-yellow .ant-latest-tooltip-arrow-content {
  background-color: #fadb14;
}
.ant-latest-tooltip-gold .ant-latest-tooltip-inner {
  background-color: #faad14;
}
.ant-latest-tooltip-gold .ant-latest-tooltip-arrow-content {
  background-color: #faad14;
}
.ant-latest-tooltip-cyan .ant-latest-tooltip-inner {
  background-color: #13c2c2;
}
.ant-latest-tooltip-cyan .ant-latest-tooltip-arrow-content {
  background-color: #13c2c2;
}
.ant-latest-tooltip-lime .ant-latest-tooltip-inner {
  background-color: #a0d911;
}
.ant-latest-tooltip-lime .ant-latest-tooltip-arrow-content {
  background-color: #a0d911;
}
.ant-latest-tooltip-green .ant-latest-tooltip-inner {
  background-color: #52c41a;
}
.ant-latest-tooltip-green .ant-latest-tooltip-arrow-content {
  background-color: #52c41a;
}
.ant-latest-tooltip-blue .ant-latest-tooltip-inner {
  background-color: #1890ff;
}
.ant-latest-tooltip-blue .ant-latest-tooltip-arrow-content {
  background-color: #1890ff;
}
.ant-latest-tooltip-geekblue .ant-latest-tooltip-inner {
  background-color: #2f54eb;
}
.ant-latest-tooltip-geekblue .ant-latest-tooltip-arrow-content {
  background-color: #2f54eb;
}
.ant-latest-tooltip-purple .ant-latest-tooltip-inner {
  background-color: #722ed1;
}
.ant-latest-tooltip-purple .ant-latest-tooltip-arrow-content {
  background-color: #722ed1;
}
.ant-latest-tooltip-rtl {
  direction: rtl;
}
.ant-latest-tooltip-rtl .ant-latest-tooltip-inner {
  text-align: right;
}
